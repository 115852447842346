.firstscreen {

  & h1 {
    color: #DCDCDC;
    max-width: 800px;
    margin: 40px 0 50px;
    font: bold 68px/64px Inter;
    letter-spacing: -0.07em;
  }

  & .banner-box {
    /*min-height: 165px;*/
    margin-bottom: 66px;
  }

  & .main-subtitle {
    color: red;
    font-size: 38px;
    font-family: Inter;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: -1.9px;

      & span {
        color: #F00;
        font-family: Inter;
        font-size: 52px;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: -2.6px;
      }
  }

  & .search-heading {
    color: #fff;
    font-family: Inter;
    font-size: 21px;
    font-weight: 600;
    line-height: 1.05;
    letter-spacing: -0.02em;
    margin: 16px 0;
  }

  & .items {
    display: flex;
    justify-content: space-between;
    height: 320px;
    margin-bottom: 50px;

    & .search {
      position: relative;
      width: 596px;
      flex-shrink: 0;
      background-color: #ffffff;
      border-radius: 12px;
      margin-right: 16px;
    }

    & .subitem {
      width: 25%;
      background: rgba(255, 255, 255, 0.4);
      backdrop-filter: blur(52px);
      border-radius: 12px;

      &:not(:last-child) {
        margin-right: 10px;
      }

      & .header {
        display: flex;
        justify-content: space-between;
        height: 44px;
        margin: 16px 12px 16px 16px;
        font: 600 20px/22px Inter;
        letter-spacing: -0.06em;

        & .icon_mobile {
          display: none;
          margin-right: 15px;
          margin-left: 18px;
        }

        & .text {
          margin-right: 10px;
        }
      }

      & .caption {
        margin: 180px 12px 12px 12px;
        font: 13px/13px Inter;
        letter-spacing: -0.04em;
        color: rgba(0, 0, 0, 0.5);
      }
    }

    & .emptyleg {
      color: #F39761;
    }

    & .sharing {

      & .header {
        opacity: 0.3;

        & .text .splitter {
          display: none;
        }
      }
    }
 }

  & .search_action {
    display: none;
    z-index: 10;
    width: 596px;
    flex-shrink: 0;
    background-color: #ffffff;
    border-radius: 12px;
    margin-right: 16px;
    margin-bottom: -197px;
  }
}

@media (min-width: 701px) {
  .firstscreen {

    & .datapicker {
      position: absolute !important;
      margin: 0 !important;
      top: 0;
      left: -12px;
    }
  }
}

@media (max-width: 1090px) {

  .firstscreen .items .heli {
    display: none;
  }
}

@media (max-width: 900px) {

  .firstscreen .items .subitem {
    width: 40%;
  }

  .firstscreen .items .sharing {
      display: none;
  }
}

@media (max-width: 700px) {

  .firstscreen {

    & h1 {
      width: auto;
      max-width: 365px;
      margin: 215px 20px 25px 20px;
      font: bold 34px/31px Inter;
      letter-spacing: -0.05em;
    }

    & .main-subtitle {
      margin: 0 20px;
      font-size: 22px;
      letter-spacing: -1.2px;
      line-height: 20px;

        & span {
          font-size: 36px;
          line-height: 40px;
          letter-spacing: -1.8px;
        }
    }

    & .banner-box {
      min-height: 0;
    }

    .search-heading {
      display: none;
    }

    & .items {
      display: block;
      height: auto;
      margin: 66px -10px 0 -10px;

      & .search {
        width: 100%;
        min-width: auto;
        background: #FCF8F2;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      }

      & .emptyleg {
        display: block;
        color: #F39761;
      }

      & .subitem {
        width: 100%;
        margin-left: 0;
        background: #FCF8F2;
        border-radius: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        & .subitem-splitter {
          display: none;
        }

        & .header {
          background: url(../assets/dropdown.png) right 19px center no-repeat;
          justify-content: flex-start;
          align-items: center;
          height: auto;
          margin: 0;
          padding: 12px 0 12px 0;
          font: 600 14px/14px Inter;
          letter-spacing: -0.02em;

          & .icon_mobile {
            display: block;
          }

          & .icon_desktop {
            display: none;
          }
        }

        & .caption {
          display: none;
        }
      }

      & .heli {
        display: block;
      }

      & .sharing {
        display: block;

        & .header {
          opacity: 0.4;

          & .text {

            & .splitter {
              display: inline;
            }

            & br {
              display: none;
            }
          }
        }
      }
    }

    & .search_action {
      position: fixed;
      top: 0;
      min-width: 100%;
      width: auto;
      height: 100%;
      background: #FFFFFF;
      border-radius: 0;
    }
  }
}
