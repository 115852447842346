$mw: 1440px; // Максимальная ширина контента
$minw: 300px;  // Минимальная ширина контента

$hp: 85px; // Внутренние отступы
$hm: calc((100% - #{$mw}) / 2); // Расчет отступов

$part-hm: 40px; // Сокращенные отсупы на разрешении меньше 1300px
$mobi-hm: 10px; // Отсупы в мобильных на меньше 700px

.flightinfo {

  .flightinfo-infoline-button-place {
    margin: 0 auto;
  }

  & .result-arrows {
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    width: 100%;
    height: 115px;
    padding: 0 calc(#{$hm} + #{$hp} - 60px);
    margin-top: -115px;
    position: relative;

    & .left-arrow {
      display: none;
      height: 100%;
      width: 40px;
      background: url(../assets/left-arrow.svg) center center/100% 100% no-repeat;
      margin-right: auto;
    }

    & .right-arrow {
      height: 100%;
      width: 40px;
      background: url(../assets/right-arrow.svg) center center/100% 100% no-repeat;
    }
  }

  & .flightinfo-mobileinfo {
    display: none;
  }

  & .flightinfo-label {
    display: flex;
    justify-content: space-between;
    padding: 45px calc(#{$hm} + #{$hp}) 0 calc(#{$hm} + #{$hp});

    & .flightinfo-label-specs {
      display: none;
    }

    & .flightinfo-label-caption {
      font: 800 40px/48px Inter;
      display: flex;
      align-items: center;

      & .flightinfo-label-caption-direction {
        margin-right: 16px;
        padding: 9px 12px;
        background: #FAA81F;
        border-radius: 8px;
        font: 600 18px/18px Inter;
        letter-spacing: -0.02em;
        color: #4E1818;
      }

      & .skeleton {
        width: 280px;
      }
    }

    & .flightinfo-label-buttons {
      display: flex;
      align-items: center;

      & .flightinfo-label-buttons-return {
        margin-right: 25px;
      }

      & .flightinfo-label-buttons-return-favorites img{
        width: 26px;
        height: 26px;
      }
    }
  }

  & .flightinfo-info {
    display: flex;
    margin-top: 40px;
    padding: 0 calc(#{$hm} + #{$hp});

    & .flightinfo-info-digits {
      height: 500px;
      width: 400px;
      min-width: 400px;
      transform: skew(-3.5deg);
      background-color: rgba(236, 222, 211, 0.53);
      border-radius: 16px;

      & .flightinfo-info-digits-content {
        transform: skew(3.5deg);

        & .flightinfo-info-digits-content-picture {
          font: 800 24px/22px Inter;
          letter-spacing: -0.04em;
          margin-top: -20px;
          padding: 92px 0 24px 70px;
          background: center top /auto 87px no-repeat;
        }

        & .flightinfo-info-digits-content-line {
          width: 330px;
          height: 1px;
          margin: 0 auto 0 auto;
          background-color: #000000;
          opacity: 0.1;
        }

        & .flightinfo-info-digits-content-specs {
          margin: 24px 0 0 35px;
          font: 500 9px/13px Inter;
          letter-spacing: 0.15em;
          text-transform: uppercase;
          color: rgba(0, 0, 0, 0.5);
        }

        & .flightinfo-info-digits-content-specs-grid {
          display: flex;
          flex-wrap: wrap;
          margin: 16px 0 0 35px;

          & .flightinfo-info-digits-content-specs-grid-item {
            width: 50%;

            & .flightinfo-info-digits-content-specs-grid-item-digit {
              font: 700 36px/32px Inter;
              letter-spacing: -0.05em;
            }

            & .flightinfo-info-digits-content-specs-grid-item-label {
              font: 500 14px/16px Inter;
              letter-spacing: -0.02em;
              margin-top: 8px;
              margin-bottom: 24px;
              max-width: 100px;
            }
          }
        }

        & .flightinfo-info-digits-content-order-info {
          margin-left: 35px;
          margin-right: 35px;
          letter-spacing: -0.02em;

          & .flightinfo-info-digits-content-order-info-label {
            font: 600 16px/14px Inter;
            color: rgba(0, 0, 0, 1);
          }

          & .flightinfo-info-digits-content-order-info-text {
            margin-top: 8px;
            font: 400 12px/16px Inter;
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }
    }

    & .flightinfo-info-pictures-wrapper {
      margin-left: 25px;
      transform: skew(-3.5deg);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 16px;

      & .gallery {
        position: absolute;
        transform: skew(3.5deg);
        margin-left: -20px;
        z-index: -1;
        width: 110%;
        min-height: 100%;

        & .image-gallery-image {
          max-height: none;
          min-height: 500px;
          object-fit: cover;
        }

        & .image-gallery-right-nav {
          background: url(../assets/right-icon.png) no-repeat;
          width: 40px;
          margin-right: 45px;
          transform: none;
          padding: 20px 20px;

          & svg { display: none; }
        }

        & .image-gallery-left-nav {
          background: url(../assets/left-icon.png) no-repeat;
          width: 40px;
          margin-left: 65px;
          transform: none;
          padding: 20px 20px;

          & svg { display: none; }
        }

        & .image-gallery-fullscreen-button {
          padding: 0;
          margin-top: 210px;
          margin-right: 60px;
          bottom: auto;
          top: 50%;
        }

        & .image-gallery-bullets {
          bottom: auto;
          top: 50%;
          margin-top: 215px;
        }
      }
    }

    & .flightinfo-info-pictures-wrapper-back {
      margin-right: 25px;
      margin-left: 0;
      order: -1;
    }
  }

  & .flightinfo-salon {
    margin-top: 40px;
    padding: 45px calc(#{$hm} + #{$hp}) 0 calc(#{$hm} + #{$hp});
    height: 600px;
    border-radius: 16px;

    & .flightinfo-salon-img {
      display: none;
    }

    & .flightinfo-salon-label {
      font: 800 40px/48px Inter;
      letter-spacing: -0.05em;
    }

    & .flightinfo-salon-grid {
      display: flex;
      margin-top: 40px;
      width: 650px;
      flex-wrap: wrap;
      font: 500 21px/14px Inter;
      letter-spacing: -0.05em;

      & .flightinfo-salon-grid-item {
        display: flex;
        width: 325px;
        margin-bottom: 35px;

        & .flightinfo-salon-grid-item-label {
          width: 190px;
        }
      }
    }
  }

  & .flightinfo-infoline {
    position: fixed;
    display: flex;
    left: 0;
    right: 0;
    bottom: 0;
    height: 130px;
    align-items: center;
    padding: 0 calc(#{$hm} + #{$hp});
    background: rgba(255, 255, 255, 0.86);
    backdrop-filter: blur(50px);
    z-index: 2;

    & .flightinfo-infoline-price {
      font: 700 52px/48px Inter;
      letter-spacing: -0.05em;
      margin-right: 120px;
      min-width: 290px;
    }

    & .flightinfo-infoline-big-button {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: 42px;
      width: 300px;
      border-radius: 6px;

      & .flightinfo-infoline-big-button-image {
        width: 40px;
      }

      & .flightinfo-infoline-big-button-label {
        font: 600 14px/14px Inter;
        letter-spacing: -0.02em;
      }
    }

    & .flightinfo-infoline-order {
      background: #F39761;
      margin-right: auto;
    }

    & .flightinfo-infoline-share-desk {
      background: #FFFFFF;
      border: 1px solid #E9E9E9;
      margin-right: 7px;
    }

    & .flightinfo-infoline-share-mobile {
      background: #FFFFFF;
      border: 1px solid #E9E9E9;
      margin-right: 50px;
    }
  }
}

.flightinfo-there {
  .result-arrows {
    height: 221px;
    margin-top: -221px;
  }
}

.flightinfo-back {
  border-width: 0;
}

.flightinfo-share {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: Inter;
  gap: 13px;
  margin-left: 50px;

  &,
  & * {
    box-sizing: border-box;
  }

  &-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: -0.02em;
    color: #000;
  }

  &-buttons {
    display: flex;
    gap: 13px;
  }

  .flightinfo-infoline-big-button {
    width: auto !important;
    padding: 0 5px;
  }
}

@media (max-width: $mw) {

  .flightinfo {

    & .result-arrows {
      padding: 0 calc(#{$hp} - 60px);
    }

    & .flightinfo-label {
      padding: 45px $hp 0 $hp;
    }

    & .flightinfo-info {
      padding: 0 $hp;
    }

    & .flightinfo-salon {
      padding: 45px $hp 0 $hp;
    }

    & .flightinfo-infoline {
      padding: 0 $hp;

      & .flightinfo-infoline-price {
        margin-right: 60px;
      }

      & .flightinfo-infoline-big-button {
        width: 200px;
      }
    }
  }
}

@media (max-width: 1300px) {

  .flightinfo {

    & .flightinfo-label {
      padding: 45px $part-hm 0 $part-hm;
    }

    & .flightinfo-info {
      padding: 0 $part-hm;
    }

    & .flightinfo-salon {
      padding: 45px $part-hm 0 $part-hm;
    }

    & .flightinfo-infoline {
      padding: 0 $part-hm;
    }
  }
}

@media (max-width: 1090px) {
  .flightinfo {

    & .flightinfo-label {
      height: 34px;

      & .flightinfo-label-caption{
        font: 800 28px/34px Inter;

        & .skeleton {
          width: 200px;
        }
      }
    }

    & .flightinfo-info .flightinfo-info-pictures-wrapper .gallery {
      width: 130%;

      & .image-gallery-left-nav {
        margin-left: 100px;
      }

      & .image-gallery-right-nav {
        margin-right: 100px;
      }

      & .image-gallery-fullscreen-button {
        margin-right: 100px;
      }
    }

    & .flightinfo-infoline {

      & .flightinfo-infoline-price {
        font: 700 36px/33px Inter;
        min-width: 190px;
      }

      & .flightinfo-infoline-big-button {
        width: 150px;
      }
    }
  }
}

@media (max-width: 900px) {
  .flightinfo {

    & .flightinfo-label {
      padding-bottom: 40px;

      & .flightinfo-label-caption {
        flex-wrap: wrap;
        width: 390px;
        font: 800 28px/34px Inter;

        & .flightinfo-label-splitter {
          display: none;
        }
      }
    }

    & .flightinfo-info .flightinfo-info-pictures-wrapper .gallery {

      & .image-gallery-right-nav {
        margin-right: 50px;
      }

      & .image-gallery-left-nav {
        margin-left: 70px;
      }

      & .image-gallery-fullscreen-button {
        margin-right: 65px;
      }
    }

    & .flightinfo-infoline {
      padding-left: 30px;

      & .flightinfo-infoline-price {
        font: 700 29px/26px Inter;
        margin-right: 15px;
        min-width: 155px;
      }

      & .flightinfo-infoline-big-button {
        width: 130px;

        & .flightinfo-infoline-big-button-label-share {
          display: none;
        }
      }

      & .flightinfo-infoline-share-desk {
        margin-right: 7px;
      }
    }

    & .flightinfo-salon {
      height: 500px;
    }
  }
}

@media (max-width: 700px) {

  .flightinfo {
    border-top: rgba(0, 0, 0, 0.1) solid 1px;
    padding: 0;

    & .result-arrows {
      display: none;
    }

    & .flightinfo-label {
      display: block;
      padding: 15px calc(#{$mobi-hm} + 5px);
      height: auto;

      & .flightinfo-label-caption{
        font: 600 21px/23px Inter;
        letter-spacing: -0.04em;
        display: block;
        width: auto;

        & .flightinfo-label-caption-direction {
          margin: 0 0 -24px auto;
          width: fit-content;
          padding: 4px 8px;
          font-size: 12px;
        }

        & .skeleton {
          width: 170px;
        }
      }

      & .flightinfo-label-buttons{
        display: none;
      }

      & .flightinfo-label-specs {
        display: flex;
        font: 400 14px/14px Inter;
        letter-spacing: -0.02em;
        color: rgba(0, 0, 0, 0.5);
        margin-top: 5px;

        & .flightinfo-label-specs-digits {
          margin-right: 15px;
        }

        & .flightinfo-label-specs-plane-type {
          margin-left: auto;
          text-transform: uppercase;
          font: 500 9px/13px Inter;
          letter-spacing: 0.15em;
        }
      }
    }

    & .flightinfo-info {
      display: block;
      padding: 0;
      margin: 0;

      & .flightinfo-info-digits {
        transform: skew(0deg);
        background: none;
        height: auto;
        width: auto;
        min-width: auto;

        & .flightinfo-info-digits-content {
          transform: skew(0deg);

          & .flightinfo-info-digits-content-picture {
            padding: 35px calc(#{$mobi-hm} + 5px) 10px calc(#{$mobi-hm} + 5px);
            margin: 0;
            height: 30px;
            font: 600 14px/14px Inter;
            letter-spacing: -0.02em;
            background: right 10px top/auto 66px no-repeat;
          }

          & .flightinfo-info-digits-content-line {
            width: 100%;
            height: 1px;
            margin: -65px 0 0 0;
            padding: 0;
            background-color: #000000;
            opacity: 0.1;
          }

          & .flightinfo-info-digits-content-specs {
            display: none;
          }

          & .flightinfo-info-digits-content-specs-grid {
            display: none;
          }

          & .flightinfo-info-digits-content-order-info {
            display: none;
          }
        }
      }

      & .flightinfo-info-pictures-wrapper {
        transform: skew(0deg);
        margin: 65px 0 0 0;
        border-radius: 0;
        height: 260px;

        & .gallery {
          transform: skew(0deg);
          margin: 0;
          z-index: -1;
          width: 100%;

          & .image-gallery-image {
            min-height: 260px;
          }

          & .image-gallery-left-nav {
            display: none;
          }

          & .image-gallery-right-nav {
            display: none;
          }

          & .image-gallery-bullets {
            margin-top: 100px;
          }
        }
      }
    }

    & .flightinfo-salon {
      margin-top: 15px;
      padding: 0 0 0 calc(#{$mobi-hm} + 5px);
      height: auto;
      background: #F9F7F1 linear-gradient(180deg, rgba(255, 255, 255, 0.96) 58.86%, rgba(255, 255, 255, 0) 94.14%) !important;

      & .flightinfo-salon-img {
        display: block;
        // position: relative;
        margin: -50px 0 -50px 0;
        z-index: 2;
        overflow: scroll;

        &::-webkit-scrollbar {
          height: 0;
          width: 0;
        }
      }

      & .flightinfo-salon-label {
        padding-top: 25px;
        font: 500 9px/13px Inter;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        opacity: 0.5;
      }

      & .flightinfo-salon-grid {
        margin-top: 15px;
        width: 300px;

        & .flightinfo-salon-grid-item {
          flex-wrap: wrap-reverse;
          width: 100px;
          margin-bottom: 15px;
          z-index: 1;

          & .flightinfo-salon-grid-item-digit {
            font: 700 21px/23px Inter;
            letter-spacing: -0.04em;
          }

          & .flightinfo-salon-grid-item-label {
            margin-top: 5px;
            font: 500 13px/14px Inter;
            letter-spacing: -0.04em;
            opacity: 0.75;
            width: 85px;
          }
        }
      }
    }

    & .flightinfo-infoline {
      flex-wrap: wrap;
      justify-content: center;
      box-sizing: border-box;
      padding: 0 calc(#{$mobi-hm} + 5px);
      height: auto;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 80%, rgba(249, 247, 241, 1) 100%);
      border-radius: 16px 16px 0 0;

      & .flightinfo-infoline-price {
        font: 700 36px/48px Inter;
        letter-spacing: -0.05em;
        margin: 10px 0 5px 0;
      }

      & .flightinfo-infoline-big-button {
        margin: 0;
        width: 100%;

        & .flightinfo-infoline-big-button-label {
          transform: skew(3.5deg);
          font: 600 14px/14px Inter;
          letter-spacing: -0.02em;
        }
      }

      & .flightinfo-infoline-share-desk {
        display: none;
      }

      & .flightinfo-infoline-button-place {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 12px;
        margin-bottom: 10px;
        width: 100%;

        & .flightinfo-infoline-button-place-share-mobile {
          display: flex;
          box-sizing: border-box;
          width: 50%;
        }

        .flightinfo-infoline-button-place-pax {
          display: flex;
          width: 50%;
          min-width: 230px;
          justify-content: center;
        }
      }
    }

    & .flightinfo-mobileinfo {
      display: block;
      padding: 25px 0 0 0;

      & hr {
        margin-top: 25px;
        background-color: #000000;
        opacity: 0.1;
      }

      & .flightinfo-info-digits-content-order-info {
        margin-top: 15px;
        letter-spacing: -0.02em;
        padding: 0 calc(#{$mobi-hm} + 5px);
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        & .flightinfo-info-digits-content-order-info-label {
          font: 600 14px/14px Inter;
          color: rgba(0, 0, 0, 1);
        }

        & .flightinfo-info-digits-content-order-info-text {
          margin-top: 8px;
          font: 400 12px/12px Inter;
          color: rgba(0, 0, 0, 0.5);
        }
      }

      & .flightinfo-info-digits-content-specs {
        padding: 0 calc(#{$mobi-hm} + 5px);
        font: 500 9px/13px Inter;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        opacity: 0.5;
      }

      & .flightinfo-info-digits-content-specs-grid {
        display: flex;
        margin-top: 15px;
        padding: 0 0 0 calc(#{$mobi-hm} + 5px);
        position: relative;
        overflow: scroll;

        &::-webkit-scrollbar {
          height: 0;
          width: 0;
        }

        & .flightinfo-info-digits-content-specs-grid-item {
          display: flex;
          flex-wrap: wrap;
          width: 110px;
          min-width: 110px;

          & .flightinfo-info-digits-content-specs-grid-item-digit {
            font: 700 21px/23px Inter;
            letter-spacing: -0.04em;
          }

          & .flightinfo-info-digits-content-specs-grid-item-label {
            margin-top: 5px;
            font: 500 13px/14px Inter;
            letter-spacing: -0.02em;
            opacity: 0.75;
          }
        }
      }
    }
  }

  .flightinfo-there {
    background: #F3EAE2;
    margin-top: -16px;
    padding-top: 24px;
    border-radius: 16px 16px 0 0;
    border-width: 0;

    .flightinfo-infoline {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 80%, rgba(243, 234, 226, 1) 100%);
    }

    .flightinfo-salon {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.96) 0%, rgba(243, 234, 226, 1) 60%);
      padding-bottom: 32px;

      & .flightinfo-salon-img {
      }
    }
  }

  .flightinfo-back {
    background: #F3EAE2;
    margin-top: -16px;
    padding-top: 24px;
    border-radius: 16px 16px 0 0;
    border-width: 0;
  }

  .flightinfo-share {
    margin: 10px 0;
  }

  .background-wrapper {
    height: 32px;
    background: #F9F7F1;
    border-top: rgba(0, 0, 0, 0.1) solid 1px;
  }
}

@media (max-width: 525px) {

  .flightinfo .flightinfo-infoline .flightinfo-infoline-button-place .flightinfo-infoline-button-place-pax {
    min-width: 240px;
    justify-content:  left;
  }
}
