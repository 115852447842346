$mw: 1440px; // Максимальная ширина контента
$minw: 300px;  // Минимальная ширина контента

$hp: 80px; // Внутренние отступы
$hm: calc((100% - #{$mw}) / 2); // Расчет отступов

$part-hm: 40px; // Сокращенные отсупы на разрешении меньше 1300px
$mobi-hm: 10px; // Отсупы в мобильных на меньше 700px

.flights .shadow_box {

  & .mobile-navigation {
    display: none;
  }

  & .info {
    display: flex;
    min-height: 90px;
    padding: 0 calc(#{$hm} + #{$hp});
    border-style: solid;
    border-color: #E0DEDA;
    border-width: 1px 0;

    & .info-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 37%;
      padding-right: 20px;
      margin-right: 20px;
      border: 0 solid #E0DEDA;
      border-right-width: 1px;

      & .info-item-code {
        display: flex;
        justify-content: right;
        max-width: 75px;
        flex-wrap: wrap;
        font: 400 21px/14px Inter;
        letter-spacing: -0.02em;
        color: #F39761;


        & .info-item-code-iata {
          font-weight: 500;
        }

        & .info-item-code-icao {
          margin-top: 6px;
        }
      }

      & .info-item-caption {
        font: 600 21px/21px Inter;
        letter-spacing: -0.02em;

        & .info-item-caption-name {
        }

        & .info-item-caption-city {
          margin-top: 6px;
          font-weight: 400;
        }

        & .info-item-caption-duration {
          margin-top: 6px;
          font-weight: 400;
          color: #7D7B79;
        }

        & .info-item-caption-direction {
          font-size: 14px;
          margin-bottom: 5px;
        }
      }

      & .info-item-caption-departure {
        cursor: pointer;
        padding-right: 10px;
      }
    }

    & .info-item-longlist {
      width: 30%;
    }

    & .info-item-last {
      border-right-width: 0;
      margin-right: 0;
      width: 26%;
    }

    & .info-long-item {
      width: 40%;

      & .info-item-caption-departure {
        width: 50%;
      }
    }
  }
}

@media (max-width: $mw) {

  .flights .shadow_box .info {
    padding: 0 $hp;
  }
}

@media (max-width: 1300px) {

  .flights .shadow_box .info {
    padding: 0 $part-hm;
  }
}

@media (max-width: 700px) {

  .flights .shadow_box {

    & .info {
      min-height: auto;
      height: 60px;
      padding: 0 $mobi-hm+6;

      & .info-item {
        box-sizing: border-box;
        align-items: flex-start;
        margin: 0;
        width: 50%;

        & .info-item-caption {
          line-height: 14px;
          font-size: 14px;

          & .info-item-caption-name {
            margin-top: 8px;
          }

          & .info-item-caption-city {
            margin-top: 2px;
          }
        }

        & .info-item-code {
          font-size: 14px;
          max-width: 40px;

          & .info-item-code-iata {
            margin-top: 8px;
          }

          & .info-item-code-icao {
            margin-top: 0;
          }
        }
      }

      & .info-item-arrival {
        padding-left: 20px;
        border: 0;
      }

      & .info-item-last {
        display: none;
      }
    }

    & .mobile-navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 47px;
      font: 600 14px/14px Inter;
      letter-spacing: -0.02em;
      padding: 5px 15px 5px 25px;

      & .mobile-navigation-quantity {
        padding-left: 25px;
        background: url(../assets/back.png) left center no-repeat;
      }
    }
  }
}
