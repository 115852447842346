$mw: 1440px; // Максимальная ширина контента
$minw: 300px;  // Минимальная ширина контента

$hp: 80px; // Внутренние отступы
$hm: calc((100% - #{$mw}) / 2); // Расчет отступов

$part-hm: 40px; // Сокращенные отсупы на разрешении меньше 1300px
$mobi-hm: 10px; // Отсупы в мобильных на меньше 700px

.flights .shadow_box,
.firstscreen {

  & .datapicker {
    display: block;
    position: relative;
    margin: 20px calc(#{$hm} + #{$hp} + 80px) -452px auto;
    z-index: 7;
    width: 820px;
    background: #FFFFFF;
    border-radius: 12px;
    transform: skew(-3.5deg);

    & .datapicker-direction {
      display: none;
    }

    & .datapicker-calendar {
      position: relative;
      transform: skew(3.5deg);
      padding-top: 70px;
      cursor: pointer;
      z-index: 15;
    }

    & .datapicker-calendar-inactive {
      height: 40px;
      margin: 0 0 -110px 700px;

      & .datapicker-calendar-inactive-content {
        display: none;
      }
    }

    & .datapicker-calendar-active {
      display: flex;
      margin: 0 0 -420px 335px;

      & img {
        margin-left: 7px;
      }
    }

    & .datapicker-date {
      transform: skew(3.5deg);
      display: flex;
      margin: 0 140px 5px 58px;
      overflow: hidden;
      cursor: pointer;

      &::-webkit-scrollbar {
        height: 0;
        width: 0;
      }

      & .datapicker-date-item {
        min-width: 61px;
        max-width: 61px;
        overflow: hidden;
        padding: 3px 8px 4px;
        margin-bottom: 15px;

        & .datapicker-date-item-data {
          font: 400 21px/22px Inter;
          letter-spacing: -0.04em;
        }

        & .datapicker-date-item-details {
          font: 400 11px/12px Inter;
          letter-spacing: -0.02em;
          color: #0000007F;
        }
      }

      & .datapicker-date-item-picked {
        background: linear-gradient(129.06deg, #F39761 6.96%, #F39761 104.75%);
        border-radius: 5px;
      }
    }

    & .datapicker-time {
      display: flex;
      flex-wrap: wrap;
      margin: 0 130px 0 70px;

      & .datapicker-time-item {

        & .datapicker-time-item-data {
          padding-left: 3px;
        }

        & .datapicker-time-item-details {
          padding-left: 3px;
        }
      }
    }

    & .datapicker-label {
      transform: skew(3.5deg);
      padding: 35px 0  25px 58px;
      font: 600 21px/14px Inter;
      letter-spacing: -0.04em;
    }

    & .datapicker-time-label {
      padding: 5px 0  25px 68px;
    }

    & .datapicker-button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 15px;
      height: 70px;
      background: #FAF7F2;
      border-radius: 0 0 12px 12px;
      cursor: pointer;

      & .datapicker-button-label {
        transform: skew(3.5deg);
        font: 800 24px/22px Inter;
        letter-spacing: -0.05em;
      }
    }

    & .datapicker-date-inactive {
      display: none;
    }
  }

  & .datapicker-inactive {
    display: none;
  }
}

@media (max-width: $mw) {

  .flights .shadow_box .datapicker {
    margin: 20px $hp -452px auto;
  }
}

@media (max-width: 1300px) {

  .flights .shadow_box .datapicker {
    margin: 20px $part-hm -452px auto;
  }
}

@media (max-width: 900px) {

  .flights .shadow_box .datapicker,
  .firstscreen .datapicker {
    width: 600px;
    transform: none;
    padding: 0 35px;
    margin: 20px auto -508px auto;

    & .datapicker-direction {
      display: flex;
      width: 100%;
      font: 600 14px/14px Inter;
      letter-spacing: -0.02em;

      & .datapicker-direction-item {
        box-sizing: border-box;
        width: 50%;
        padding: 10px 0 0 16px;
        border-right: 1px solid #ECDED3;
      }

      & .datapicker-direction-item-back {
        border: 0;
      }
    }

    & .datapicker-date {
      transform: none;
      margin: 0 60px 5px 0;
    }

    & .datapicker-label {
      transform: none;
      padding-left: 0;
    }

    & .datapicker-time {
      transform: none;
    }

    & .datapicker-calendar {
      transform: none;
    }

    & .datapicker-calendar-inactive {
      margin-left: 560px;
    }

    & .datapicker-calendar-active {
      margin-left: 185px;
    }

    & .datapicker-button {
      margin: 15px -35px 0 -35px;

      & .datapicker-button-label {
        transform: none;
      }
    }
  }
}

@media (max-width: 700px) {

  .flights .shadow_box,
  .firstscreen {

    & .datapicker-inactive {
      display: block;

      & .datapicker-calendar {
        display: none;
      }
    }

    & .datapicker {
      position: static;
      display: flex;
      flex-wrap: wrap;
      width: auto;
      margin: 0;
      padding: 0;
      background: none;
      border: none;

      & .datapicker-date {
        max-width: 100%;
        overflow: scroll;
        margin: 0;
        padding: 8px 0 10px 15px;

        & .datapicker-date-item {
          min-width: 61px;
          margin-bottom: 0;
          margin-right: 10px;
        }
      }

      & .datapicker-date-inactive {
        display: flex;
        max-width: 50%;
        box-sizing: border-box;
        border-right: 1px solid #ECDED3;
      }

      & .datapicker-label {
        display: none;
      }

      & .datapicker-button {
        display: none;
      }

      & .datapicker-time {
        flex-wrap: nowrap;
        border-top: 1px solid #ECDED3;
        margin: 0;

        & .datapicker-time-item {

          & .datapicker-time-item-data {
            padding-left: 0;
          }

          & .datapicker-time-item-details {
            padding-left: 0;
          }
        }
      }

      & .datapicker-date-back {
        padding-left: 12px;
        border-left: 0;
      }
    }
  }
}
